import { useEffect } from 'react';
import { useState, useCallback, useContext } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { toDisplayString, specificityList } from '../../model/specificity';
import { TableStateContext } from '../../model/table_state_context';
import { useSearchParams } from 'react-router-dom';

const initialSpecificity = specificityList.reduce((acc, spec) => ({ ...acc, [spec]: true }), {});

const specificityAsArray = (specMap) => Object.keys(specMap).filter((spec) => specMap[spec]);

export default function TargetlistSearchSimple() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { setStartSearchForString, startSearchForString, searchResults } = useContext(TableStateContext);
    const [specificityValue, setSpecificityValue] = useState(initialSpecificity);
    const [initial, setInitial] = useState(false);
    const [listSearchResults, setListSearchResults] = useState([]);
    var filters = document.getElementById("tl-search-simple-filter-container");
    var results = document.getElementById("tl-search-simple-filter-results");

    const changeValue = useCallback((event) => {
        setStartSearchForString(event.target.value, specificityAsArray(specificityValue));
        if (event.target.value === null || event.target.value === "") {
            filters?.classList.remove("show");
            results?.classList.remove("show");
            setInitial(false);
        } else {
            setInitial(true);
        }
    }, [setStartSearchForString, specificityValue, filters, results])

    const searchFor = useCallback(({ title, taxonomy }) => {
        // newest one on top of the list, and filter from old list if it is already there
        document.getElementById("tl-search-simple").value = '';
        document.getElementById("tl-search-simple-filter-container").classList.remove("show");
        document.getElementById("tl-search-simple-filter-results").classList.remove("show");
        
        setSearchParams({ q: title, showfrom: taxonomy })
    }, [setSearchParams]);

    useEffect(() => {
        let newList = {};
        let hasAny = false;

        Object.entries(searchResults).forEach(([specificity, value]) => {
            if (value.count > 0 && specificityValue[specificity]) {
                newList[`${specificity} ${value.count}`] = value.items.map(item => ({ label: item, onClick: () => searchFor({ title: item, taxonomy: specificity }) }));
                hasAny = true;
            }
        });
        if (hasAny && initial) {
            console.log("testa")
            filters?.classList.add("show");
            results?.classList.add("show");
        } else {
            if (Object.keys(newList).length > 0) {
                filters?.classList.remove("show");
                results?.classList.remove("show");
            }
        }
        setListSearchResults(newList);

    }, [searchResults, specificityValue, setSearchParams, filters, results, searchFor])

    const toggleSpecificity = useCallback((event) => {
        const { value, checked } = event.target;
        setSpecificityValue(old => ({ ...old, [value]: checked }));
        setStartSearchForString(startSearchForString, specificityAsArray({ ...specificityValue, [value]: checked }))
    }, [setSpecificityValue, startSearchForString, setStartSearchForString, specificityValue]);

    return (
        <div className="tl-search-main-simple">
            <div className="tl-search-inner">
                <div className="tl-search-top">
                    <div className="tl-search-container">
                        <div className="text-input tl-search-box-simple" tabIndex="0">
                            <DebounceInput
                                type="search"
                                debounceTimeout={400}
                                onChange={changeValue}
                                placeholder={`Search...`}
                                minLength={2}
                                id="tl-search-simple"
                            />
                            <div className="tl-search-simple-filter-container" id="tl-search-simple-filter-container">
                                {Object.keys(specificityValue).map((spec, idx) =>
                                    <div key={idx} className="tl-search-filter">
                                        <input type="checkbox" value={spec} disabled={searchResults[spec] ? searchResults[spec].items.length === 0 : false} onChange={(ev) => toggleSpecificity(ev)} defaultChecked={specificityValue[spec]} />
                                        <label key={idx}>
                                            {toDisplayString(spec)}
                                        </label>
                                    </div>
                                )}
                            </div>
                            <ul className="tl-search-simple-filter-results" id="tl-search-simple-filter-results">
                                {Object.entries(listSearchResults).map(([key, items], idx) =>
                                    <li key={idx}>
                                        {key}
                                        <ul className="tl-search-filter-result-categories">{items.map((item, idx2) =>
                                            <li key={idx2}><button onClick={item.onClick}>{item.label}</button></li>
                                        )}</ul>
                                    </li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}