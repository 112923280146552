import React, { useState, useRef, useEffect } from 'react';

export default function LocalityFilter({ countries, onChange = () => { } }) {
    const [filter, setFilter] = useState('');
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [placeholderText, setPlaceholderText] = useState("Search countries");
    const dropdownRef = useRef(null);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        const updatedCountries = checked
            ? [...selectedCountries, value]
            : selectedCountries.filter(country => country !== value);
        updateSelectedCountries(updatedCountries);
    };

    const updateSelectedCountries = (updatedCountries) => {
        setSelectedCountries(updatedCountries);
        onChange(updatedCountries);

        if (updatedCountries.length === 1) {
            setPlaceholderText("1 country selected");
        } else if (updatedCountries.length > 1) {
            setPlaceholderText(`${updatedCountries.length} countries selected`);
        } else {
            setPlaceholderText("Search countries");
        }
    };

    const filteredCountries = countries
        .filter(country => country.name.toLowerCase().includes(filter.toLowerCase()))
        .filter(country => !selectedCountries.includes(country.name));

    const showDropdown = () => {
        setDropdownVisible(true);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
            setFilter('');
        }
    };

    const handleClearAll = () => {
        updateSelectedCountries([]);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="position-relative filter-label" onClick={showDropdown}>
            <span>Locality</span>
            <input
                type="text"
                value={filter}
                onChange={handleFilterChange}
                placeholder={placeholderText}
                className="text-input"
            />
            {dropdownVisible && (
                <div className="multiselect-dropdown" ref={dropdownRef}>
                    <div className="dropdown-list">
                        {selectedCountries.length > 0 && (
                            <>
                                <div className="selected-list">
                                    {selectedCountries.map((countryName) => {
                                        const country = countries.find(c => c.name === countryName);
                                        return (
                                            <div className="multiselect-dropdown-item" key={country.id}>
                                                <input
                                                    type="checkbox"
                                                    id={country.id}
                                                    value={country.name}
                                                    checked={true}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor={country.id}>
                                                    {country.name}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                        {filteredCountries.map((country) => (
                            <div className="multiselect-dropdown-item" key={country.id}>
                                <input
                                    type="checkbox"
                                    id={country.id}
                                    value={country.name}
                                    checked={selectedCountries.includes(country.name)}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={country.id}>
                                    {country.name}
                                </label>
                            </div>
                        ))}
                    </div>
                    {selectedCountries.length > 0 &&
                        <button type="button" onClick={handleClearAll} className="btn-main clear-all">
                            Clear All
                        </button>
                    }
                </div>
            )}
        </div>
    );
}
