import { useEffect, useContext } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TableStateContext } from '../../model/table_state_context';
import Breadcrumbs from './breadcrumbs';
import TargetlistSearchSimple from './target_list_search _simple';
import { useTargetlist } from '../../api/target_list_data';
import Charts from './charts';
import ExportCsv from './export_csv';
import './charts-carousel.css';

export default function ChartsCarousel({ showTable, carouselItems }) {
    // use this as reverse index (0 is the last one, move to the left if it increases, easier to calculate offsets etc)
    const [currentChartIndex, setCurrentChartIndex] = useState(0);
    const [popupVisible, setPopupVisible] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const table = useContext(TableStateContext);
    const [allBarcodes, setAllBarcodes] = useState();
    const [ariseBarcodes, setAriseBarcodes] = useState();
    const [otherBarcodes, setOtherBarcodes] = useState();
    const [collected, setCollected] = useState();
    const [wanted, setWanted] = useState(false);
    const [locality, setLocality] = useState();
    const [occStatus, setOccStatus] = useState();
    var info = document.getElementById("info-popup");

    useEffect(() => {

        if (searchParams.has('selected')) {
            setCurrentChartIndex(Number(searchParams.get('selected')))
        } else {
            setCurrentChartIndex(0)
        }

        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [searchParams, table]);

    useEffect(() => {
        if (table) {
            const row = table.table.getRowModel().rows[0];
            if (row) {
                setAllBarcodes(row.original.allBarcodes);
                setAriseBarcodes(row.original.ariseBarcodes);
                setOtherBarcodes(row.original.otherBarcodes);
                setLocality(row.original.speciesLocality.join(', '));
                setOccStatus(row.original.speciesOccurrenceStatus);
                setWanted(row.original.wanted);
                setCollected(row.original.collected);
            }
        }
    }, [table]);

    const toggleInfoPopup = function () {
        info.classList.toggle("show");
        setPopupVisible(!popupVisible);
    }

    const onPopupBlur = useCallback((e) => {
        setPopupVisible(false);
        if (e.relatedTarget == null) {
            info.classList.remove("show");
        } else if (e.relatedTarget.id != "info-popup" || e.relatedTarget.id == null) {
            info.classList.remove("show");
        }
    }, [popupVisible]);

    const updateCurrentChartIndex = useCallback((index) => {
        // setCurrentChartIndex(index);
        searchParams.set('selected', index);
        setSearchParams(searchParams)
    }, [searchParams, setSearchParams]);


    return (
        <div className="container-main table-page">
            {/*<TargetlistSearchSimple  />*/}
            <Breadcrumbs currentIndex={currentChartIndex} crumbItems={carouselItems.map((item, index) => ({ text: item[1], action: () => updateCurrentChartIndex(-carouselItems.length + index + 1) }))} />
            <div className="tl-carousel-btn-container">
                <button onClick={() => updateCurrentChartIndex(currentChartIndex - 1)} disabled={currentChartIndex === -carouselItems.length + 1} className={`carousel-btn left ${currentChartIndex === -carouselItems.length + 1 ? "disabled" : ""}`}></button>
                <button onClick={() => updateCurrentChartIndex(currentChartIndex + 1)} disabled={currentChartIndex === 0} className={`carousel-btn right ${currentChartIndex === 0 ? "disabled" : ""}`}></button>
            </div>
            <div className="carousel-container h-100">
                {carouselItems.map((item, index) => {
                    return (
                        <div key={index} className={"carousel-item" + (item[0] == "species" && viewportWidth < 1023 ? " idcard" : "")} style={{ transform: `translate(${(carouselItems.length - 1 - index + currentChartIndex) * -100}%)` }}>
                            {item[0] == "species" && viewportWidth < 1023 ?
                                <div className="species-idCard">
                                    <h2>{item[1]}</h2>
                                    {wanted ?
                                        <div className="speciesStatus wanted mb-4">Wanted</div>
                                        :
                                        <div>
                                            <div className="speciesStatus unwanted">Unwanted</div>
                                            <p className="d-inline-flex mt-3 mb-5"><span className="btn-icon infoBtn red">i</span> We already received enough specimens of this species.
                                                If you already collected a specimen, you can still send it to ARISE, but please do not collect more of it.</p>
                                        </div>
                                    }
                                    <p className="barcodes">Total barcodes <span className="underline"></span> {allBarcodes}</p>
                                    <p className="barcodes">Arise barcodes <span className="underline"></span> {ariseBarcodes}</p>
                                    <p className="barcodes">Other barcodes <span className="underline"></span> {otherBarcodes}</p>
                                    <p className="barcodes">Other barcodes <span className="underline"></span> {otherBarcodes}</p>
                                    <p className="barcodes">Collected <span className="underline"></span> {collected}</p>
                                    {locality !== "" && locality !== null ?
                                        <p><span className="title">Locality:</span> {locality}</p>
                                        : null}
                                    <p className="barcodes">Occurrence status:<span className="underline"></span> {occStatus}</p>
                                </div>
                                :
                                <Charts searchFor={item[1]} showFrom={item[0]} currentIndex={currentChartIndex} />
                            }
                        </div>
                    )
                })}
            </div >

            <div className="charts-btn-container">
                <button className="btn-icon infoBtn" onClick={toggleInfoPopup} onBlur={onPopupBlur}>i</button>
                {viewportWidth > 1023 ?
                    <button className="btn-main primary chevron-down desktop-only" onClick={showTable}>view table</button>
                    :
                    <ExportCsv table={table} />
                }
            </div>


        </div >
    )
}