export default function InfoPopup({ toggleInfoPopup }) {

    return (
        <div id="info-popup" className="info-popup" tabIndex="0" onBlur={toggleInfoPopup}>
            <div className="info-popup-inner">
                <p className="info-title">Barcode coverage</p>
                <ul>
                    <li>ARISE: Dutch barcodes</li>
                    <li>Non-ARISE: non-Dutch barcodes</li>
                    <li>All: all existing barcodes in the world</li>
                </ul>
                <p className="info-text">ARISE aims to produce three DNA barcodes for each species, to include in the Dutch DNA reference database.
                    The percentage values indicate the coverage of DNA barcodes available for the taxon selected (genus level or higher).
                    For species level, we indicate whether a species is wanted or unwanted. <br />
                    A species is wanted, untill ARISE reached:<br />
                </p>
                <ul>
                    <li>3 barcodes: unwanted</li>
                    <li>2 barcodes + 1 specimen collected: unwanted</li>
                    <li>1 barcode + 2 specimen collected: unwanted</li>
                    <li>0 barcodes + 3 specimens collected: unwanted</li>
                </ul>
                <p className="info-title">Usage</p>
                <p className="info-text">
                    The information can be used to identify gaps in our reference database, and create a target list for species to be collected and sequenced within the ARISE program. By adding public BOLD data, it enables users to see which gaps in the Dutch barcode database may already be covered by using international data, which helps in the prioritisation of collection efforts.</p>
            </div>
        </div>
    )
}