import { useCallback, useContext } from 'react';

import PercentageFilter from './percentage_filter';
import OccurrenceStatusFilter from './occurrence_status_filter';
import LocalityFilter from './locality_filter';

import { TableStateContext } from '../../model/table_state_context';

export default function TargetlistFilterSidebar({ toggleFilters }) {
    const { setPercentageFilter, setOccurrenceStatusFilter, setLocalityFilter, countries } = useContext(TableStateContext);

    const addAllPercentageCoverageFilter = useCallback(({ min, max }) => {
        setPercentageFilter(prevFilter => ({ ...prevFilter, 'allPercentageCoverage': { min, max } }));
    }, [setPercentageFilter]);

    const addArisePercentageCoverageFilter = useCallback(({ min, max }) => {
        setPercentageFilter(prevFilter => ({ ...prevFilter, 'arisePercentageCoverage': { min, max } }));
    }, [setPercentageFilter]);

    const addOtherPercentageCoverageFilter = useCallback(({ min, max }) => {
        setPercentageFilter(prevFilter => ({ ...prevFilter, 'otherPercentageCoverage': { min, max } }));
    }, [setPercentageFilter]);

    const addOccurrenceStatusFilter = useCallback((occurrenceStatus) => {
        setOccurrenceStatusFilter(occurrenceStatus);
    }, [setOccurrenceStatusFilter]);

    function onFilterBlur(event) {
        if (event.relatedTarget == null) {
           document.getElementById("filters-sidebar").classList.add("hide");
        }
    }

    return (
        <div className="filters-sidebar hide" id="filters-sidebar" tabIndex="0" onBlur={onFilterBlur}>
            <div className="filters-sidebar-inner">
            <div className="d-flex flex-row w-100 justify-content-between mb-4">
                <h3>Filters</h3>
                <button className="btn-icon close-btn" onClick={toggleFilters}></button>
            </div>
            <p className="filter-category">Species occurrence status</p>
            <OccurrenceStatusFilter onFilterChange={addOccurrenceStatusFilter} />
            <p className="filter-category">Barcode coverage</p>
            <div className="percentage-filters">
                <PercentageFilter title="Cov % between" onChange={addAllPercentageCoverageFilter} />
                <PercentageFilter title="Arise % between" onChange={addArisePercentageCoverageFilter} />
                <PercentageFilter title="Other % between" onChange={addOtherPercentageCoverageFilter} />
            </div>
            <p className="filter-category">Locality</p>
            <LocalityFilter countries={countries} onChange={(countryList) => setLocalityFilter(countryList)} />
            </div>
        </div>
    )
}