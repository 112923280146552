import { useEffect, useState, useContext } from "react";
import { TableStateContext } from '../../model/table_state_context';
import { PieChart } from './chart'
import { Specificity } from '../../model/specificity';


// contains alls the other components that make up the target list page
// also deals with url parameters etc.
export default function Charts({ searchFor = '', showFrom = Specificity.unknown }) {
    const [nlPercentage, setNlPercentage] = useState(undefined);
    const [globalPercentage, setGlobalPercentage] = useState(undefined);
    const [totalPercentage, setTotalPercentage] = useState(undefined);
    // const [loading, setLoading] = useState(true);
    const { chartPercentages } = useContext(TableStateContext);


    useEffect(() => {
        chartPercentages({ searchTerm: searchFor, groupBy: showFrom }).then((data) => {
            if (data.arisePercentageCoverage !== undefined) {
                // console.log(`arisePercentageCoverage ${data.arisePercentageCoverage}`);
                setNlPercentage(Math.round(data.arisePercentageCoverage * 100));
                setGlobalPercentage(Math.round(data.otherPercentageCoverage * 100));
                setTotalPercentage(Math.round(data.allPercentageCoverage * 100));
                // setLoading(false);
            }
        });
    }, [chartPercentages, searchFor, showFrom]);
    // if (loading) return <div>Loading...</div>;

    return (

        <div className="results-charts" id="charts-container">
            <div className="charts-title">
                <p>{showFrom}</p>
                <h1>{searchFor}</h1>
            </div>
            <div className="charts-main">
                <PieChart id={`donutchartNL-${showFrom}`} percentage={nlPercentage} colors={['#47397B', '#DAD7E5']} title="ARISE" cssClass="netherlands" />
                <PieChart id={`donutchartGlobal-${showFrom}`} percentage={globalPercentage} colors={['#009EC5', '#99D8E8']} title="Non-ARISE" cssClass="global" />
                <PieChart id={`donutchartTotal-${showFrom}`} percentage={totalPercentage} colors={['#A0D35E', '#C6E59E']} title="All" cssClass="total" />
            </div>

        </div>
    );
}
