import React, {
  Component
} from 'react';
import {
  Container
} from 'reactstrap';
import {
  NavMenu
} from './NavMenu';


export class Layout extends Component {
    static displayName = Layout.name;
    componentDidMount() {
        var mainLayout = document.getElementById("main-layout");
        console.log(window.innerHeight);
        //todo: finish mobile responsiveness to fit mainlayout inbetween browser navbars
    }

  render() {
    return (
        <div className="main-layout" id="main-layout">
            <NavMenu />
            <Container className="content-main"> {
          this.props.children
        } </Container>
      </div>
    );
  }
}