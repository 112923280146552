import { useContext, useEffect } from 'react';
import { TableStateContext } from '../../model/table_state_context';
import {
    flexRender,

} from '@tanstack/react-table'
import TableHeader from '../table_header';
import ErrorBoundary from '../error_boundary';



export default function TargetlistGrid({ hideColumns }) {
    const { table, loading, numberOfItems } = useContext(TableStateContext);

    let sorting = table.getState().sorting;
    // only show the countries column in the species 'groupby'

    const showSpeciesStatus = (row) => {
        if (row.original.wanted) {
            return <div className="speciesStatus wanted">Wanted</div>;
        } else {
            return <div className="speciesStatus unwanted">Unwanted</div>;
        }
    };

    useEffect(() => {
        if (!loading && table && hideColumns) {
            table.getAllColumns().forEach(column => {
                // a bit tricky, if columns get hidden, we call toggleVisibility on the column
                // but that will trigger a tablestateprovider change, which means everyone will update,
                // so we run again, hiding the same columns but triggering a state change, ad inifinitum
                if ((hideColumns.includes(column.id) && table.getColumn(column.id).getIsVisible())
                    || (!hideColumns.includes(column.id) && !table.getColumn(column.id).getIsVisible())) {
                    table.getColumn(column.id).toggleVisibility(!hideColumns.includes(column.id));
                }
            });
        }
    }, [hideColumns, table, loading, numberOfItems]);

    useEffect(() => {
        table.setPageSize(20);
    }, [])

    if (loading) {
        if (numberOfItems == 0) {
            return <h3 className="mt-3">No results</h3>
        } else {
            return <h3 className="mt-3">loading...</h3>
        }
    }

    return (
        <>
            <ErrorBoundary>
                <div id="table-tooltip-portal"></div>
                <div className="table-border mt-3">
                    <div className="table-container">
                        <table className="table-main">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => {
                                            return (
                                                <TableHeader
                                                    key={header.id}
                                                    header={header}
                                                    sorting={sorting}
                                                    table={table}
                                                />
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}>
                                                {cell.column.id == 'speciesStatus' ? showSpeciesStatus(row) : flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-footer">
                    <span className="flex items-center gap-1">
                        Go to page:
                        <input
                            type="number"
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                table.setPageIndex(page)
                            }}
                            className="ml-2 text-input small"
                        />
                    </span>
                    <div className="d-flex">
                        <button
                            className="tf-button first-page"
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                        >
                        </button>
                        <button
                            className="tf-button prev"
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        >
                        </button>
                        <span className="d-flex h-100 align-items-center mx-3">
                            {table.getState().pagination.pageIndex + 1} of{' '}
                            {table.getPageCount()}
                        </span>
                        <button
                            className="tf-button next"
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                        </button>
                        <button
                            className="tf-button last-page"
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}
                        >
                        </button>
                    </div>
                    <select
                        className="show-pg-select"
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>

            </ErrorBoundary>
        </>

    );
}
