import Targetlist from './pages/target_list';

const AppRoutes = [
    {
        index: true,
        element: <Targetlist />
    },
    {
        path: '/targetlist',
        element: <Targetlist />
    }

];

export default AppRoutes;
