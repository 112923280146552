import { createContext, useMemo, useState, useEffect } from 'react';
import { useTargetlist } from '../api/target_list_data';
import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	getPaginationRowModel,
	useReactTable,
} from '@tanstack/react-table'
// import { rankItem } from '@tanstack/'
import { getTargetlistAccessor } from '../model/target_list_columns';
import { useCallback } from 'react';

export const TableStateContext = createContext();

const columnHelper = createColumnHelper();

export const TableStateProvider = ({ children }) => {
	const [sorting, setSorting] = useState([]) // accessible elsewhere through table.getState().sorting
	const [columnVisibility, setColumnVisibility] = useState({})
	const [data, setData] = useState([]);
	const [groupBy, setGroupBy] = useState('species');
	const [localityFilter, setLocalityFilter] = useState([]);
	// properly initialize the percentage filter or the fetch will happen for each instantiation of such a filter (so 3 extra times)
	const [percentageFilter, setPercentageFilter] = useState({ 'allPercentageCoverage': { min: 0, max: 1 }, 'arisePercentageCoverage': { min: 0, max: 1 }, 'otherPercentageCoverage': { min: 0, max: 1 } });
	const [occurrenceStatusFilter, setOccurrenceStatusFilter] = useState([]);
	const [wantedFilter, setWantedFilterState] = useState('all');
	// startFilter and searchFilter function pretty much the same, but startFilter is used for the initial search on the start page, 
	// and searchFilter is used for the search when the table is shown
	const [startFilter, setStartFilter] = useState({ searchString: '', columns: [] });
	const [searchFilter, setSearchFilter] = useState('');
	// grouped by specificity, first X results of the search
	// e.g., only first 2 results: { 'family': {count: 0, items: []}, 'species' : {count: 8, items: ['polycera kernowensis', 'medicago polycerata']}, 'genus': {count: 2, items: ['polycera', 'polycersus']} }
	const [searchResults, setSearchResults] = useState({});
	const { getAll, countries, searchItems, filterBySearchTerm, chartPercentages, occStatus } = useTargetlist();

	useEffect(() => {
		getAll({ groupBy, localityFilter, percentageFilter, occurrenceStatusFilter, searchFilter, wantedFilter }).then(setData)
	}, [getAll, groupBy, localityFilter, percentageFilter, occurrenceStatusFilter, searchFilter, wantedFilter]);

	useEffect(() => {
		if (!startFilter.searchString) {
			setSearchResults({});
		} else {
			searchItems(startFilter.searchString).then(setSearchResults);
		}
	}, [searchItems, startFilter.searchString]);

	const columnKeys = useMemo(() => ['kingdom', 'phylum', 'class', 'order', 'family', 'genus', 'species', 'speciesStatus', 'speciesTotal', 'speciesWithBarcode',
		'allBarcodes', 'allPercentageCoverage', 'ariseBarcodes', 'arisePercentageCoverage',
		'otherBarcodes', 'otherPercentageCoverage', 'speciesOccurrenceStatus', 'collected', 'speciesLocality'], []);

	const columns = useMemo(() => columnKeys.map(key => columnHelper.accessor(key, getTargetlistAccessor(key))), [columnKeys]);
	const table = useReactTable({
		data,
		columns,
		state: { sorting, columnVisibility },
		onSortingChange: setSorting,
		onColumnVisibilityChange: setColumnVisibility,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	})

	// // columnsToSearch is an array of column names
	// 'start search' is de search done on the start page, which will filter the data, subsequent searches are done within this data
	const setStartSearchForString = useCallback((searchString, columnsToSearch) => {
		// console.log(`startscreen searching for '${searchString}' in ${columnsToSearch} ${typeof columnsToSearch}`);
		if (typeof columnsToSearch === 'string') {
			if (startFilter.columns.length === 1 && startFilter.columns[0] === columnsToSearch) {
				return;
			}
		}
		else if (searchString !== startFilter.searchString && (columnsToSearch.length === startFilter.columns.length && columnsToSearch.forEach(columnName => startFilter.columns.includes(columnName)))) {
			return; // don't do anything if the search string is the same and if the columns to search are the same
			// since it's an object we have to check before doing the 'setState', or it will trigger change detection
		}
		if (typeof columnsToSearch === 'string') {
			columnsToSearch = [columnsToSearch];
		}
		setStartFilter(x => x = { searchString, columns: columnsToSearch });
	}, [startFilter.columns, startFilter.searchString, setStartFilter]);

	const setTableSearchForString = useCallback((searchString) => {
		if (searchString !== searchFilter) {
			setSearchFilter(searchString);
		}
	}, [searchFilter, setSearchFilter]);

	const setWantedFilter = useCallback((value) => {
		setWantedFilterState(value);
	}, []);

	const value = useMemo(() => ({
		table, countries, occStatus, setGroupBy,
		setLocalityFilter, setPercentageFilter, setOccurrenceStatusFilter, setWantedFilter,
		setStartSearchForString, startSearchForString: startFilter.searchString, searchResults,
		setTableSearchForString, tableSearchForString: searchFilter,
		filterBySearchTerm: filterBySearchTerm,
		chartPercentages,
		loading: !data.length,
		numberOfItems: data.length
	}),
		// don't remove 'sorting' from the dependency list, otherwise the table won't update properly when sorting
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[table, countries, setGroupBy, setLocalityFilter, setPercentageFilter, setOccurrenceStatusFilter, setStartSearchForString, setWantedFilter, startFilter, searchResults, setSearchFilter, searchFilter, data, chartPercentages, table.getState()]);

	return (
		<TableStateContext.Provider value={value}>
			{children}
		</TableStateContext.Provider>
	);
}