
// don't use Symbol() here, these are intermingled
// with a lot of other strings (not related to specificity) 
// e.g. as keys for the columns etc
export const Specificity = {
	kingdom: 'kingdom',
	phylum: 'phylum',
	class: 'class',
	order: 'order',
	family: 'family',
	genus: 'genus',
	species: 'species',
	unknown: 'unknown',
};

export const specificityList = Object.values(Specificity).filter(x => x !== 'unknown');

export function toDisplayString(specificity) {
	switch (specificity) {
		case Specificity.kingdom: return 'Kingdom';
		case Specificity.phylum: return 'Phylum';
		case Specificity.class: return 'Class';
		case Specificity.order: return 'Order';
		case Specificity.family: return 'Family';
		case Specificity.genus: return 'Genus';
		case Specificity.species: return 'Species';
		default: throw new Error(`Unknown specificity: ${specificity}`);
	}
}


// luckily github copilot generated these for me :)
export function isEqualOrMoreSpecific(lhs, rhs) {
	if (lhs === rhs) return true;
	switch (lhs) {
		case Specificity.kingdom: return false;
		case Specificity.phylum: return rhs === Specificity.kingdom;
		case Specificity.class: return rhs === Specificity.kingdom || rhs === Specificity.phylum;
		case Specificity.order: return rhs === Specificity.kingdom || rhs === Specificity.phylum || rhs === Specificity.class;
		case Specificity.family: return rhs === Specificity.kingdom || rhs === Specificity.phylum || rhs === Specificity.class || rhs === Specificity.order;
		case Specificity.genus: return rhs === Specificity.kingdom || rhs === Specificity.phylum || rhs === Specificity.class || rhs === Specificity.order || rhs === Specificity.family;
		case Specificity.species: return true;
		default: throw new Error(`Unknown specificity: ${lhs} compared with ${rhs}`);
	}
}