import { Specificity, toDisplayString, isEqualOrMoreSpecific } from './specificity';

// define columns (how to group, sortable, etc)

// when grouping by phylum, show kingdom and phylum
// when group by order, show kingdom, phylum, class, order
// and so on
const TARGET_LIST_COLUMNS = [
	{ key: Specificity.kingdom, enableMultiSort: true, },
	{ key: Specificity.phylum, enableMultiSort: true, },
	{ key: Specificity.class, },
	{ key: Specificity.order },
	{ key: Specificity.family },
	{ key: Specificity.genus },
	{ key: Specificity.species },
	{
		key: 'speciesStatus', header: () => 'Species status',
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => items.reduce((acc, x) => acc + x, 0)
	},
	{
		key: 'speciesTotal', header: () => 'Total species',
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => items.reduce((acc, x) => acc + x, 0)
	},
	{
		key: 'speciesWithBarcode', header: () => 'Species with barcode',
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => items.reduce((acc, x) => acc + x, 0)
	},
	{
		key: 'allBarcodes', header: () => 'All',
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => items.reduce((acc, x) => acc + x, 0)
	},
	{
		key: 'allPercentageCoverage', header: () => 'All %', cell: info => (info.getValue() * 100).toLocaleString(undefined, { maximumFractionDigits: 1 }),
		enableSorting: false, enableColumnFilter: true, enableGlobalFilter: false, showFilterInline: false, combineItems: (ignore, { groupBy, items }) => items.map(i => i.speciesWithBarcode).reduce((acc, x) => acc + x) / items.map(i => i.speciesTotal).reduce((acc, x) => acc + x),
		convertItem: (item) => item.speciesWithBarcode / item.speciesTotal,
	},
	{
		key: 'ariseBarcodes', header: () => 'ARISE',
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => items.reduce((acc, x) => acc + x, 0),
		tooltipTitle: 'ARISE Barcodes',
		tooltipText: `
		ARISE barcodes are all barcodes produced within ARISE, meaning it corresponds to:
		<ul>
			<li>the barcodes produced by Naturalis barcoding campaign e.g. FES;</li>
			<li>the recent barcodes generated from Nanopore sequencing in the ARISE lab;</li>
			<li>the Fungi barcodes produced by Westerdijk Fungal Biodiversity Institute.</li>
		</ul>`
	},
	{
		key: 'hasAriseBarcode', header: () => '', cell: info => null,  // will not be displayed, used for easier calculation of percentage
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => items.reduce((acc, x) => acc + x, 0), convertItem: item => item.ariseBarcodes > 0 ? 1 : 0
	},
	{
		key: 'arisePercentageCoverage', header: () => 'ARISE %', cell: info => (info.getValue() * 100).toLocaleString(undefined, { maximumFractionDigits: 1 }),
		enableSorting: false, enableColumnFilter: true, enableGlobalFilter: false, showFilterInline: false, combineItems: (ignore, { groupBy, items }) => items.map(i => i.hasAriseBarcode).reduce((acc, x) => acc + x) / items.map(i => i.speciesTotal).reduce((acc, x) => acc + x),
		convertItem: (item) => (item.ariseBarcodes > 0 ? 1 : 0) / item.speciesTotal, // actually item.hasAriseBarcode / item.speciesTotal, but hasArise is not in the original json, so can't use it when converting
	},
	{
		key: 'otherBarcodes', header: () => 'Non-ARISE',
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => items.reduce((acc, x) => acc + x, 0),
		tooltipTitle: 'Non-ARISE barcodes',
		tooltipText: 'Non-ARISE barcodes are all barcodes of Dutch species produced by other projects or organizations inside and outside of the Netherlands.'
	},
	{
		key: 'hasOtherBarcode', header: () => '', cell: info => null, // will not be displayed, used for easier calculation of percentage
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => items.reduce((acc, x) => acc + x, 0), convertItem: item => item.otherBarcodes > 0 ? 1 : 0
	},
	{
		key: 'otherPercentageCoverage', header: () => 'Non-ARISE %', cell: info => (info.getValue() * 100).toLocaleString(undefined, { maximumFractionDigits: 1 }),
		enableSorting: false, enableColumnFilter: true, enableGlobalFilter: false, showFilterInline: false, combineItems: (ignore, { groupBy, items }) => items.map(i => i.hasOtherBarcode).reduce((acc, x) => acc + x) / items.map(i => i.speciesTotal).reduce((acc, x) => acc + x),
		convertItem: (item) => (item.otherBarcodes > 0 ? 1 : 0) / item.speciesTotal, // actually item.hasOtherBarcode / item.speciesTotal, but hasArise is not in the original json, so can't use it when converting
	},
	{
		key: 'speciesOccurrenceStatus', header: () => 'Occ. status',
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => [...new Set(items)].filter(item => item?.length > 0).join(','),
		tooltipTitle: 'Occurrence status',
		tooltipText: '<a href="https://www.nederlandsesoorten.nl/content/statuscodes-voorkomen-nederland" target="_blank" rel="noopener noreferrer">Ecological status of species in the Netherlands</a>'
	},
	{
		key: 'speciesLocality', header: () => 'Locality', cell: info => info.getValue().join(';'),
		enableSorting: false, enableColumnFilter: false, combineItems: (items) => [...new Set(items.flat())], convertItem: (item) => item.speciesLocality?.split(';').map(x => x.trim()) ?? []
	},
	{
		key: 'collected',
		header: () => 'Collected',
		tooltipTitle: 'Collected',
		tooltipText: 'All specimens registered in ADA Sampling Tool.',
	},
	{ key: 'wanted', header: () => 'Wanted' }


]


// create the accessor info for the tanstack table columns
function getTargetlistAccessor(key) {
    const targetItem = TARGET_LIST_COLUMNS.find(column => column.key === key);
    if (!targetItem) {
        console.error(`ERROR: No target list accessor info found for key ${key}`);
        return null;
    }

    let targetlistAccessor = {
        key: key,
        header: targetItem.header ?? toDisplayString(key),
        cell: targetItem.cell ?? ((info) => info.getValue()),
        enableMultiSort: targetItem.enableMultiSort ?? false,
        enableColumnFilter: targetItem.enableColumnFilter ?? true,
        enableGlobalFilter: targetItem.enableGlobalFilter ?? true,
        enableSorting: targetItem.enableSorting ?? true,
        showFilterInline: targetItem.showFilterInline ?? false,
        tooltipTitle: targetItem.tooltipTitle,
        tooltipText: targetItem.tooltipText,
    };

    if (targetItem.filterFn) {
        targetlistAccessor.filterFn = targetItem.filterFn;
    }

    return targetlistAccessor;
}


// when performing a groupBy, combine the items (could be sum (for numbers), 
// merge (for countries), of simply erase contents (if grouped on something else))
function groupTargetlistItemsByKey(key, items, { groupBy }) {
	if (!items || items.length === 0) {
		return undefined;
	}
	if (items.length === 1) {
		return items[0][key];
	}
	const targetItem = TARGET_LIST_COLUMNS.filter(column => column.key === key)[0];
	if (!targetItem) {
		// console.error(`ERROR no target list accessor info found for key ${key}`);
		return items[0][key];
	}
	targetItem.combineItems = targetItem.combineItems ?? ((items, { groupBy }) => isEqualOrMoreSpecific(groupBy, key) ? items[0] : '');
	let result = targetItem.combineItems(items.map(item => item[key]), { groupBy, items });
	return result;
}

function createTargetlistItemFromJson(jsonItem) {
	const result = {};
	TARGET_LIST_COLUMNS.forEach(column => {
		result[column.key] = column.convertItem ? column.convertItem(jsonItem) : jsonItem[column.key];
	});
	return result;
}

export { getTargetlistAccessor, groupTargetlistItemsByKey, createTargetlistItemFromJson }