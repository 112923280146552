import { useState } from 'react';
import { CSVLink } from "react-csv";

export default function ExportCsv({ table }) {
	const [loading, setLoading] = useState(false);
	const [csvData, setCsvData] = useState([]);

	const formatCsvData = (event, done) => {
		const headers = table.getHeaderGroups()[0].headers
		if (!loading) {
			setLoading(true);
			const filteredData = table.getSortedRowModel().flatRows.map(row => headers.map(h => h.id === 'speciesStatus' ?  (row.original.wanted ?  "Wanted" : "Unwanted") : row.getValue(h.id)));
			setCsvData([headers.map(h => h.id), ...filteredData]);
			console.log(headers)
			setLoading(false);
			done(true);
		}
	}
	return <CSVLink
		data={csvData}
		filename={"target_list.csv"}
		asyncOnClick={true}
		onClick={formatCsvData}
		className="btn-main export green"
		separator=";"
	>
		{loading ? 'Preparing download...' : 'Export'}
	</CSVLink>;
}