import { useSearchParams } from 'react-router-dom';

export default function Breadcrumbs({ currentIndex, crumbItems }) {

    //var nextSiblings = document.querySelectorAll("#active-breadcrumb ~ button");
    //nextSiblings.forEach(
    //    function (link) {
    //        link.style.opacity = "0.3";
    //    }
    //);

    return (
        <div className="breadcrumbs-container">
            {Object.keys(crumbItems).map((item, index) => {
                if (currentIndex + crumbItems.length - 1 === index) {
                    return <button className="breadcrumb-link active" id="active-breadcrumb" key={index} onClick={crumbItems[item].action}>{crumbItems[item].text}</button> //href={`?q=${item[1]}&showFrom=${item[0]}`}>{item[1]}</a>;
                } else {
                    return <button className="breadcrumb-link" key={index} onClick={crumbItems[item].action}>{crumbItems[item].text}</button> //href={`?q=${item[1]}&showFrom=${item[0]}`}>{item[1]}</a>;
                }
            })}


        </div>
    )
}