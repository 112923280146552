
import { DebounceInput } from 'react-debounce-input';

// not used right now, was used when doing inline filtering (so input field in header of table)
export default function TableFilterInput({
	column,
	table,
}) {
	const firstValue = table
		.getPreFilteredRowModel()
		.flatRows[0]?.getValue(column.id)

	const columnFilterValue = column.getFilterValue()
	const sortedUniqueValues = Array.from(column.getFacetedUniqueValues().keys()).slice(0, 1000).sort();

	return typeof firstValue === 'number' ? (
		<div>
			<div className="flex space-x-2">
				<DebounceInput
					type="number"
					min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
					max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
					value={columnFilterValue?.[0] ?? ''}
					onChange={e =>
						column.setFilterValue(old => [e.target.value, old?.[1]])
					}
					placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
						? `(${column.getFacetedMinMaxValues()?.[0]})`
						: ''
						}`}
					className="w-24 border shadow rounded"
					debounceTimeout={400}
				/>
				<DebounceInput
					type="number"
					min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
					max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
					value={columnFilterValue?.[1] ?? ''}
					onChange={e =>
						column.setFilterValue(old => [old?.[0], e.target.value])
					}
					placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
						? `(${column.getFacetedMinMaxValues()?.[1]})`
						: ''
						}`}
					className="w-24 border shadow rounded"
					debounceTimeout={400}
				/>
			</div>
			<div className="h-1" />
		</div>
	) : (
		<>
			<datalist id={column.id + 'list'}>
				{sortedUniqueValues.map((value) => (
					<option value={value} key={value} />
				))}
			</datalist>
			<DebounceInput
				type="text"
				value={(columnFilterValue ?? '')}
				debounceTimeout={400}
				onChange={e => column.setFilterValue(e.target.value)}
				placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
				className="w-36 border shadow rounded"
				list={column.id + 'list'}
			/>
			<div className="h-1" />
		</>
	)
}