import { useEffect } from 'react';
import { useState } from 'react';

export default function RecentlyViewed({ onChange, recentlyViewed }) {

    return (
        <div className="recently-viewed">
            <p className="rv-main-title">Recently viewed</p>
            {recentlyViewed.map((item, index) => (
                index <= 3 ?
                <div className="rv-item" key={index} onClick={() => onChange({ title: item.title, taxonomy: item.taxonomy })}>
                    <p className="rv-title">{item.title}</p>
                    <p className="rv-taxonomy">{item.taxonomy}</p>
                    </div>
                    : null
            ))
            }
        </div>
    );
}
